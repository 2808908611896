import React from "react";
import { ScrollToTop } from "./utils";
import { Layout } from "./components";
import 'bootstrap/dist/css/bootstrap.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./App.css";
import {
  AboutUs,
  Blog,
  RecursosLegales,
  ErrorPage,
  //Home,
  BlogPost,
  LandingOrganizers,
  Afiliados,
} from "./pages";
import { BrowserRouter, Routes, Route } from "react-router-dom";

const App = () => (
  <BrowserRouter>
    <ScrollToTop />
    <Routes>
      <Route
        path="organizadores-llamada"
        element={<LandingOrganizers isDemo={false} />}
      ></Route>
      <Route path="/" element={<Layout />}>
        <Route index element={<LandingOrganizers isDemo />} />
        <Route path="about-us" element={<AboutUs />} />
        <Route path="blog" element={<Blog />} />
        <Route path="blog/:id/:title" element={<BlogPost />} />
        <Route path="recursos-legales" element={<RecursosLegales />} />
        <Route path="privacy-policy" element={<RecursosLegales />} />
        <Route path="tos" element={<RecursosLegales />} />
        <Route path="afiliados" element={<Afiliados />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

export default App;
