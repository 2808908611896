import React from "react";
import { Box, Typography, Button } from "@mui/material";
import ia from "../../assets/IA.png";

const HeaderOrganizers = ({ cta }) => {
  return (
    <Box id="home" sx={styles.banner}>
      <Box sx={styles.banner.container}>
        <Box sx={styles.banner.row}>
          <Box sx={styles.banner.col}>
            <Box sx={styles.banner.content}>
              <Typography
                variant="h1"
                sx={{
                  fontFamily: `"Blatant", sans-serif`,
                  fontSize: "90px !important",
                  fontWeight: "lighter !important",
                  "@media screen and (max-width: 1080px)": {
                    fontSize: "4em !important",
                    whiteSpace: "pre-wrap",
                  },
                  span: {
                    background: "#0477CF",
                    // eslint-disable-next-line no-dupe-keys
                    background:
                      "linear-gradient(to right, #0FB0F5 0%, #940FFA 100%)",
                    "-webkit-background-clip": "text",
                    "-webkit-text-fill-color": "transparent",
                    backgroundImage:
                      "linear-gradient(rgb(255, 255, 255) 42.31%, rgba(255, 255, 255, 0.5) 94.23%)",
                  },
                }}
              >
                Gestión de eventos{" "}
                <span style={{ position: "relative", whiteSpace: "nowrap", fontFamily: `"Blatant", sans-serif`, fontWeight: "bold !important" }}>
                3.0
                  <svg
                    viewBox="0 0 272 8"
                    focusable="false"
                    class="chakra-icon chakra-icon css-fsr128"
                    style={{
                      width: "100%",
                      position: "absolute",
                      bottom: "-12px",
                      left: "0",
                    }}
                  >
                    <svg
                      width="272"
                      height="8"
                      viewBox="0 0 272 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M269.324 7.99445C165.26 1.51861 48.1761 5.29673 2.66743 7.99495C1.28982 8.07663 0.0981205 7.15583 0.00570267 5.93829C-0.0867152 4.72075 0.955142 3.66752 2.33276 3.58584C47.9765 0.879607 165.306 -2.90852 269.675 3.58635C271.053 3.67205 272.091 4.72832 271.994 5.94559C271.897 7.16285 270.702 8.08016 269.324 7.99445Z"
                        fill="url(#paint0_linear_999_11627)"
                      ></path>
                      <defs>
                        <linearGradient
                          id="paint0_linear_999_11627"
                          x1="24.9606"
                          y1="6.97522"
                          x2="209.694"
                          y2="-91.8367"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#3D1DFF"></stop>
                          <stop offset="0.223953" stop-color="#6147FF"></stop>
                          <stop offset="0.46354" stop-color="#D451FF"></stop>
                          <stop offset="0.750004" stop-color="#EC458D"></stop>
                          <stop offset="1" stop-color="#FFCA8B"></stop>
                        </linearGradient>
                      </defs>
                    </svg>
                  </svg>
                </span>
                {/* {" "}<br/>para eventos */}
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontSize: "20px !important",
                  width: "800px !important",
                  margin: "2em auto !important",
                  "@media screen and (max-width: 830px)": {
                    width: "70% !important",
                    margin: "12px auto 0 auto",
                  },
                }}
              >
                Duplicá tus ganancias y <b>evolucioná</b> la gestión de tus eventos. Transformá compradores en promotores, vendé con tu marca, con asientos numerados, recibí pagos al instante y deja que la <b><i>IA</i></b><img src={ia} style={{ width: "25px"}} alt="AI logo"/> te ayude a <b>vender más</b>.
              </Typography>
              <Box
                sx={{
                  background: "#0477CF",
                  width: "100%",
                  // eslint-disable-next-line no-dupe-keys
                  background:
                    "linear-gradient(to right, #0FB0F5 0%, #940FFA 100%)",
                  "-webkit-background-clip": "text",
                  "-webkit-text-fill-color": "transparent",
                }}
              >
                <Box sx={styles.ctaContainer}>
                  <Button sx={styles.banner.button} href="#solicitaunademo">
                    {cta}
                  </Button>
                  <Button
                    sx={styles.banner.buttonSecondary}
                    href="https://panel.fanz.com.ar"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Empezá ahora
                  </Button>
                </Box>
                {/* <p>
                  No se requiere tarjeta de crédito, cargos de configuración ni
                  suscripción
                </p> */}
              </Box>
            </Box>
          </Box>
          <Box sx={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden', width: '100%', background: '#000', margin: '1.5em 0' }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/AyLTWLfw7Zs"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HeaderOrganizers;

const styles = {
  ctaContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "10px",
    width: "100%",
    a: {
      width: "100%",
    },
    "@media (max-width: 1080px)": {
      justifyContent: "center",
      paddingBottom: "30px",
    },
    "@media (max-width: 506px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  banner: {
    padding: "200px 0 60px 0",
    background:
      "radial-gradient(58.11% 44.54% at 51.59% -9.61%, rgb(180, 176, 254) 0%, rgb(54, 50, 133) 22.92%, rgb(17, 13, 91) 42.71%, rgb(5, 3, 39) 88.54%)",
    position: "relative",
    overflow: "hidden",
    "@media screen and (max-width: 1080px)": {
      padding: "140px 0 60px 0",
    },
    container: {
      display: "flex",
      alignItems: "center",
      maxWidth: "1140px",
      width: "80%",
      margin: "0 auto",
      justifyContent: "center",
      "@media screen and (max-width: 1080px)": {
        width: "90%",
      },
    },
    row: {
      flexWrap: "wrap",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      img: {
        width: "100%",
      },
      "@media screen and (max-width: 768px)": {
        justifyContent: "center",
        gap: "0",
        img: {
          width: "100%",
        },
      },
    },
    button: {
      fontSize: "16px",
      whiteSpace: "nowrap",
      height: "48px",
      borderRadius: "45px",
      cursor: "pointer",
      padding: "0.5em 1em",
      background:
        "linear-gradient(15.46deg, rgb(74, 37, 225) 26.3%, rgb(123, 90, 255) 86.4%)",
      boxShadow: "none",
      color: "#fff",
      maxWidth: "250px",
      transition: "all .5s ease-in-out",
      "-webkit-text-fill-color": "#fff !important",
      width: "100%",
      "@media (max-width: 401px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      "&:hover": {
        transform: "translateY(-2px)",
      },
    },
    buttonSecondary: {
      fontSize: "16px",
      whiteSpace: "nowrap",
      height: "48px",
      borderRadius: "45px",
      cursor: "pointer",
      padding: "0.5em 1em",
      border: "1px solid #464567",
      maxWidth: "250px",
      width: "100%",
      "-webkit-text-fill-color": "#fff !important",
      boxShadow: "none",
      "@media (max-width: 401px)": {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      },
      "&:hover": {
        background: "rgba(255, 255, 255, 0.16)",
      },
    },
    col: {
      width: "100%",
      paddingBottom: "18px",
    },
    content: {
      paddingRight: 0,
      "@media screen and (max-width: 1080px)": {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      },
      h1: {
        lineHeight: 1.18,
        color: "#ffffff",
        position: "relative",
        fontWeight: "bold",
        width: "100%",
        textAlign: "center",
        margin: "0 auto",
        fontSize: ["35px", "45px", "45px", "68px"],
        "&:before": {
          content: '""',
          width: ["290px", null, null, null, null, "260px", "381px"],
          height: "15px",
          backgroundPosition: "center center",
          backgroundSize: "100% 100%",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          bottom: "-15px",
          right: ["15px", null, null, null, null, "140px", "100px"],
          display: ["none", null, null, null, null, "block"],
        },
      },
      h3: {
        lineHeight: "36px",
        margin: "1em 0 1.5em 0",
        fontWeight: 400,
        width: "100%",
        color: "#fff",
        fontSize: ["24px", "30px"],
        paddingRight: "0 !important",
        textAlign: "center",
        pr: ["15px", 0],
        br: {
          display: ["none", null, null, null, null, "block"],
        },
      },
    },
  },
};
