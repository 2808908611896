import React from "react";
import AppFeature from "./AppFeature";
// Feature images
import whiteLabel from "../../assets/1-white-label.jpg";
import analytics from "../../assets/3-Analiticas.jpg";
import dynamicPricing from "../../assets/4-dynamic-pricing.jpg";
import referals from "../../assets/5-Referidos.jpg";
import aiChatbot from "../../assets/6-AI Chatbot.jpg";
import mailing from "../../assets/7-mailing.jpg";


const features = [
  {
    imageLeft: true,
    title: { __html: "Marca blanca" },
    subtitle:
      "Dale a tu marca el reconocimiento que merece con una personalización completa",
    bullets: [
      "Utiliza tu propio dominio",
      "Integra tu logo y estilo",
      "Definí tus politicas de reembolso y privacidad",
    ],
    image: whiteLabel,
    screenshot: true,
  },
  {
    title: { __html: "Asientos numerados" },
    subtitle:
      "Ofrece a tus asistentes una experiencia única y personalizada con asientos numerados.",
    bullets: [
      "Crea planos 3D interactivos",
      "Personalizá tu disposición de asientos",
      "Envia cortesías con un click y más.",
    ],
    videoSrc: "https://fanz-landing.s3.amazonaws.com/numbered.mp4",
    screenshot: true,
    imageLeft: false,
  },
  {
    title: { __html: "Analíticas" },
    subtitle:
      "Obtené información valiosa sobre tus eventos y asistentes para mejorar tu estrategia de marketing.",
    bullets: [
      "Mapa de calor",
      "Embudo de ventas",
      "Visitas, clicks y conversión",
    ],
    image: analytics,
    imageLeft: true,
    screenshot: true,
  },
  {
    title: { __html: "Precios dinámicos" },
    subtitle:
      "Optimizá tus precios y aumentá tus ventas con nuestra herramienta de IA para precios dinámicos.",
    bullets: [
      "Hasta 2x más recaudación",
      "Aumenta la conversión",
      "Ajuste automático de precios",
    ],
    image: dynamicPricing,
    imageLeft: false,
    screenshot: true,
    poweredByAi: true,
  },
  {
    title: { __html: "Referidos" },
    subtitle:
      "Reembolsos automáticos a compradores que compartan tus eventos con sus amigos.",
    bullets: [
      "Aumenta tus ventas un 20%",
      "Crecimiento orgánico",
      "Convertí asistentes en promotores",
    ],
    image: referals,
    screenshot: true,
    imageLeft: true,
  },
  {
    title: { __html: "Chatbot AI" },
    subtitle:
      "Automatizá la atención al cliente y reducí la carga de trabajo de tu equipo.",
    bullets: [
      "Automatizá 96% de las consultas",
      "Reducí el tiempo de respuesta a 2 segundos",
      "35% reducción en costos de operación",
    ],
    image: aiChatbot,
    screenshot: true,
    imageLeft: false,
    poweredByAi: true,
  },
  {
    title: { __html: "Mailing" },
    subtitle:
      "Promocioná tus eventos y vendé más con nuestra herramienta de mailing integrada.",
    bullets: [
      "Consegui más ventas con recordatorios",
      "Envia promociones y descuentos",
      "Creá campañas rápido con IA generativa",
    ],
    image: mailing,
    screenshot: true,
    imageLeft: true,
    poweredByAi: true,
  },
];

const AppFeaturesOrganizers = () => {
  return (
    <div
      style={{ paddingTop: "140px", marginTop: "-60px" }}
      id="caracteristicas"
    >
      {features.map((feature, idx) => (
        <AppFeature {...feature} key={idx} />
      ))}
    </div>
  );
};

export default AppFeaturesOrganizers;
