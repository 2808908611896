import { Box, Typography } from "@mui/material";
import React from "react";

const Testimonials = () => {
  return (
    <Box component="section" sx={styles.fevCourse}>
      <Box sx={styles.fevCourse.container}>
        <Typography variant="h2">Historias de Éxito</Typography>
        <Box sx={styles.imagesContainer}>
          <Box sx={styles.videoWrapper}>
            <Box sx={styles.iframeContainer}>
              <iframe
                src="https://www.youtube.com/embed/4Dq9tP46x34"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography sx={styles.videoTitle} variant="subtitle1">Enigmax</Typography>
            <Typography sx={styles.videoDescription} variant="subtitle2">Experiencia teatral inmersiva.</Typography>
          </Box>
          <Box sx={styles.videoWrapper}>
            <Box sx={styles.iframeContainer}>
              <iframe
                src="https://www.youtube.com/embed/O9RbYQga8wk"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography sx={styles.videoTitle} variant="subtitle1">Colegio Northlands</Typography>
            <Typography sx={styles.videoDescription} variant="subtitle2">Obras musicales, ferias, y eventos de recaudación.</Typography>
          </Box>
          <Box sx={styles.videoWrapper}>
            <Box sx={styles.iframeContainer}>
              <iframe
                src="https://www.youtube.com/embed/l3a2NhBXdHM"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                allowFullScreen
              ></iframe>
            </Box>
            <Typography sx={styles.videoTitle} variant="subtitle1">ACEDU</Typography>
            <Typography sx={styles.videoDescription} variant="subtitle2">Fiesta provincial del caballo (+15.000 personas).</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Testimonials;

const styles = {
  fevCourse: {
    marginBottom: "80px",
    marginTop: "-1px",
    borderBottomLeftRadius: "80px",
    borderBottomRightRadius: "80px",
    background: "#050327",
    paddingBottom: "35px",
    "@media (max-width: 1057px)": {
      marginBottom: "50px",
    },
    h2: {
      margin: "0 0 16px 0",
      fontSize: "20px",
      color: "#fff",
    },
    container: {
      display: "flex",
      alignItems: "center",
      maxWidth: "1140px",
      width: "80%",
      margin: "0 auto",
      flexDirection: "column",
    },
  },
  imagesContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
    gap: "1.5em",
    width: "100%",
    margin: "1.5em 0",
    "@media (max-width: 1057px)": {
      flexWrap: "wrap",
    },
  },
  videoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    maxWidth: "560px",
  },
  iframeContainer: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%',
    background: '#000',
    overflow: 'hidden',
    marginBottom: '0.5em',
    '& iframe': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  videoTitle: {
    fontSize: "16px",
    color: "#fff",
    textAlign: "center",
    marginBottom: "0.5em",
  },
  videoDescription: {
    fontSize: "14px",
    color: "#ccc",
    textAlign: "center",
    marginBottom: "1em",
  },
};
