import React from "react";
import { Box, Container, Typography } from "@mui/material";

const CallToAction = ({ calendar, title, subtitle }) => {
  const iframeHeight = window.innerWidth <= 768 ? "1250px" : "720px";
  
  return (
    <Box sx={styles.wrapper} id="solicitaunademo">
      <Container sx={styles.container} >
        <Typography as="h2" sx={styles.title}>
          {title}
        </Typography>
        <Typography as="p" sx={styles.subtitle}>
          {subtitle}
        </Typography>
        <iframe
          title={"hubspot"}
          style={{
            width: "100%",
            height: iframeHeight,
            border: 0,
            frameBorder: 0,
          }}
          scrolling="no"
          src={calendar}
        />
      </Container>
    </Box>
  );
};

export default CallToAction;

const styles = {
  wrapper: {
    margin: "0 auto",
    maxWidth: "1140px",
    marginBottom: "60px",
    paddingTop: "60px",
    marginTop: "-60px",
    "@media (max-width: 768px)": {
      marginBottom: 0,
    },
  },
  title: {
    paddingTop: "1em",
    textAlign: "center",
    margin: "0px",
    color: "rgb(18, 15, 67)",
    fontSize: "38px",
    fontWeight: "700",
    marginBottom: ".5em",
  },
  subtitle: {
    paddingTop: 0,
    paddingBottom: "2em",
    textAlign: "center",
    margin: "0px",
    color: "black",
    fontSize: "20px",
    fontWeight: "400",
    marginBottom: ".5em",
  },
};
