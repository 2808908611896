import React, { useState } from "react";
import { Link } from "react-router-dom";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import FanzWhiteLogo from "../../assets/fanz-white-logo.png";
import {
  FaDiscord,
  FaRedditAlien,
  FaTwitter,
  FaTelegramPlane,
  FaInstagram,
} from "react-icons/fa";
import {
  Drawer,
  Box,
  Button,
  List,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";

const MobileDrawer = ({ isScrolled }) => {
  const [state, setState] = useState(false);
  const [expandedMenu, setExpandedMenu] = useState(null);

  const toggleDrawer = (open) => () => {
    setState(open);
  };

  const toggleMenuExpansion = (menuName) => {
    if (expandedMenu === menuName) {
      setExpandedMenu(null); // If the menu is already expanded, collapse it
    } else {
      setExpandedMenu(menuName); // If another menu is expanded, or no menu is expanded, open the clicked menu
    }
  };

  const styles = {
    handler: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexShrink: "0",
      width: "26px",
      padding: 0,
      color: isScrolled ? "#050327" : "#f1f8ff",

      "@media screen and (min-width: 1039px)": {
        display: "none",
      },
    },

    drawer: {
      width: "100%",
      height: "100%",
    },

    close: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      position: "absolute",
      top: "30px",
      right: "30px",
      zIndex: "1",
    },

    content: {
      width: "100%",
      height: "100%",
      display: "flex",
      flexDirection: "column",
      color: "white",
      pt: "30px",
      pb: "40px",
      px: "30px",
    },

    menu: {
      cursor: "pointer",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      marginTop: "30px",
      a: {
        textDecoration: "none",
        fontSize: "20px",
        color: "#FFFFFF",
        padding: "4px 0 4px 6px",
        marginBottom: "8px",
        cursor: "pointer",
      },
    },

    button: {
      fontSize: "16px",
      whiteSpace: "nowrap",
      height: "48px",
      borderRadius: "45px",
      cursor: "pointer",
      textTransform: "capitalize",
      padding: "0.5em 1em",
      border: "1px solid #464567",
      maxWidth: "250px",
      width: "100%",
      color: "#fff",
      background: "rgba(255, 255, 255, 0.16)",
    },
    social: {
      width: "35px",
      height: "auto",
      color: "#FFFFFF",
      padding: 0,
      fontSize: 20,
      flexShrink: 0,
      justifyContent: "flex-start",
      marginTop: "3px",
      marginLeft: "4px",
      "&:hover": {
        color: "primary.main",
      },
    },
  };

  return (
    <>
      <Button onClick={toggleDrawer(true)}>
        <MenuIcon
          fontSize="large"
          style={{ color: isScrolled ? "rgb(74, 37, 225)" : "#fff" }}
        />
      </Button>
      <Drawer anchor="left" open={state} onClose={toggleDrawer(false)}>
        <Box
          sx={{
            background: "#050327",
            height: "100%",
            width: 270,
            padding: "2em 1em",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              justifyContent: "space-between",
            }}
          >
            <div>
              <img src={FanzWhiteLogo} width="80px" alt="fanz white logo" />
              <List style={{ paddingTop: "30px" }}>
                <ListItem component={Link} to="#home">
                  <ListItemText
                    primaryTypographyProps={{ style: { color: "#fff" } }}
                    primary="Inicio"
                    onClick={toggleDrawer(false)}
                  />
                </ListItem>
                <ListItem onClick={() => toggleMenuExpansion("documentation")}>
                  <ListItemText
                    primaryTypographyProps={{ style: { color: "#fff" } }}
                    primary="Documentación"
                  />
                  <ListItemIcon>
                    {expandedMenu === "documentation" ? (
                      <ExpandLess style={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore style={{ color: "#fff" }} />
                    )}
                  </ListItemIcon>
                </ListItem>
                <Collapse
                  in={expandedMenu === "documentation"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      component="a"
                      href="#beneficios"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Beneficios"
                      />
                    </ListItem>
                    <ListItem
                      component="a"
                      href="#comparativa"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Comparativa"
                      />
                    </ListItem>
                    <ListItem
                      component="a"
                      href="#caracteristicas"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Caracteristicas"
                      />
                    </ListItem>
                  </List>
                </Collapse>
                <ListItem
                  component={Link}
                  to="/blog"
                  onClick={toggleDrawer(false)}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: { color: "#fff" } }}
                    primary="Blog"
                  />
                </ListItem>
                <ListItem onClick={() => toggleMenuExpansion("resources")}>
                  <ListItemText
                    primaryTypographyProps={{ style: { color: "#fff" } }}
                    primary="Recursos"
                  />
                  <ListItemIcon>
                    {expandedMenu === "resources" ? (
                      <ExpandLess style={{ color: "#fff" }} />
                    ) : (
                      <ExpandMore style={{ color: "#fff" }} />
                    )}
                  </ListItemIcon>
                </ListItem>
                <Collapse
                  in={expandedMenu === "resources"}
                  timeout="auto"
                  unmountOnExit
                >
                  <List component="div" disablePadding>
                    <ListItem
                      component="a"
                      href="/afiliados"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Afiliados"
                      />
                    </ListItem>
                    {/* <ListItem
                      component="a"
                      href="https://help.fanz.events"
                      target="_blank"
                      rel="noreferrer"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Centro de Ayuda"
                      />
                    </ListItem> */}
                    {/* <ListItem
                      component="a"
                      href="https://help.fanz.events/preguntas-frecuentes"
                      target="_blank"
                      rel="noreferrer"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Preguntas Frecuentes"
                      />
                    </ListItem> */}
                    {/* <ListItem
                      component="a"
                      href="https://help.fanz.events"
                      target="_blank"
                      rel="noreferrer"
                      onClick={toggleDrawer(false)}
                    >
                      <ListItemText
                        primaryTypographyProps={{
                          style: { color: "#fff", marginLeft: "24px" },
                        }}
                        primary="Contáctanos"
                      />
                    </ListItem> */}
                  </List>
                </Collapse>
              </List>
            </div>
            <div>
              <Box
                sx={{ py: "15px", display: "flex", justifyContent: "center" }}
              >
                <a
                  target="_blank"
                  href="https://twitter.com/FanzEvents"
                  rel="noreferrer"
                >
                  <IconButton sx={styles.social} aria-label="list icon">
                    <FaTwitter />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  href="https://discord.gg/mKk6y9HR6h"
                  rel="noreferrer"
                >
                  <IconButton sx={styles.social} aria-label="list icon">
                    <FaDiscord />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  href="https://t.me/FanzEvents"
                  rel="noreferrer"
                >
                  <IconButton sx={styles.social} aria-label="list icon">
                    <FaTelegramPlane />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  href="https://www.instagram.com/fanzevents/"
                  rel="noreferrer"
                >
                  <IconButton sx={styles.social} aria-label="list icon">
                    <FaInstagram />
                  </IconButton>
                </a>
                <a
                  target="_blank"
                  href="https://www.reddit.com/r/fanzevents/"
                  rel="noreferrer"
                >
                  <IconButton sx={styles.social} aria-label="list icon">
                    <FaRedditAlien />
                  </IconButton>
                </a>{" "}
              </Box>
              <Box sx={{ padding: 2 }}>
                <Button
                  fullWidth
                  variant="contained"
                  component="a"
                  style={{
                    fontSize: "16px",
                    whiteSpace: "nowrap",
                    height: "48px",
                    borderRadius: "45px",
                    cursor: "pointer",
                    textTransform: "capitalize",
                    padding: "0.5em 1em",
                    border: "1px solid #464567",
                    maxWidth: "250px",
                    width: "100%",
                    color: "#fff",
                    background: "rgba(255, 255, 255, 0.16)",
                  }}
                  href="/#solicitaunademo"
                  onClick={toggleDrawer(false)}
                >
                  Obtenela Yá
                </Button>
              </Box>
            </div>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default MobileDrawer;
